import React from "react"
import "./layout.css"
import Navbar from "./Navbar"
// import Sidebar from "./Sidebar"
// import Footer from "./Footer"
const Layout = ({ children, curmenu }) => {
  return (
    <>
      <Navbar curmenu={curmenu} />
      <div className="pt-12 md:pt-24">{children}</div>
    </>
  )
}

export default Layout
