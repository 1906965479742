import React from "react"
import { GoMail } from "react-icons/Go"
import { ImPhone } from "react-icons/Im"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div className="container mx-auto max-w-screen-xl pt-3 pb-2 container-pad text-white">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 pb-2 md:pb-0 pb-3 sm:pb-0">
            <p className="py-3 text-2xl">О нас</p>
            <p className="py-3 text-sm leading-5 text-justify tracking-wider">
              Если работу нужно сделать, ее стоит сделать хорошо. Кроме того,
              что бы просто выполнить работу по договору, мы стараемся ее
              сделать так что бы на результат труда было приятно смотреть и не
              стыдно показать. Качество работы, по нашему мнению, зависит не
              только от отношения к работе, но и от наличия качественного и
              современного инструмента, именно поэтому, считаем необходимым
              инвестировать в средства производства. И это то, что позволяет нам
              быть быстрее конкурентов в своей работе и выполнять работу
              качественно!
            </p>
            <div className="py-3 text-5xl font-bank-gothic hidden sm:block">
              РИШОН
            </div>
          </div>
          <div className="pb-3 sm:pb-0 hidden md:block">
            <p className="py-3 text-2xl">&nbsp;</p>
            <p className="py-3 leading-5">
              <Link
                to="/projects/"
                className="link py-1 font-medium leading-5 hover:border-sand border-b-4 border-transparent focus:outline-none focus:border-sand transition duration-150 ease-in-out"
              >
                Проекты
              </Link>
            </p>
            <p className="py-3 leading-5 ">
              <Link
                to="/contact/"
                className="link py-1 font-medium leading-5 hover:border-sand border-b-4 border-transparent focus:outline-none focus:border-sand transition duration-150 ease-in-out"
              >
                Контакты
              </Link>
            </p>
          </div>
          <div className="pb-3 sm:pb-0">
            {/* <div className="mb-2 md:mb-0 pb-3 sm:pb-0 sm:px-12"> */}
            <p className="py-3 text-2xl">Контакты</p>
            <p className="py-3 leading-5">
              <GoMail className="inline text-2xl mr-5" />
              <a
                href="mailto:rishon@rishon.ru"
                className="hover:text-white  footer__mail"
              >
                rishon@rishon.ru
              </a>
            </p>
            <p className="py-3 leading-5">
              <ImPhone className="inline text-2xl mr-5" />
              <a
                href="tel:+74955455650"
                className="py-1 font-medium leading-5 hover:border-sand border-b-4 border-transparent focus:outline-none focus:border-sand transition duration-150 ease-in-out"
              >
                +7 (495) 545-5650
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
