import React from "react"
import { useState } from "react"
import logo from "../assets/logo.png"
import { Link } from "gatsby"
// import PageLinks from "../constants/links"
const Navbar = () => {
  const [isOn, setIsOn] = useState(false)
  const test = () => {
    setIsOn(!isOn)
    if (!isOn) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }
  return (
    <>
      <nav className={`${isOn ? "nav-open" : "nav-close"} `}>
        <div className="fixed bg-white shadow-md sm:shadow-no z-50 w-full flex flex-wrap">
          <div className="container container-pad max-w-screen-xl mx-auto px-6 sm:px-6 lg:px-16">
            <div className="relative flex items-center justify-between h-16 md:h-24">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* <!-- Mobile menu button--> */}
                <button
                  onClick={test}
                  className="inline-flex items-center justify-center p-2 -m-3 rounded-md outline-none text-black hover:text-black focus:outline-none focus:text-black transition duration-150 ease-in-out"
                  aria-label="Main menu"
                  aria-expanded="false"
                >
                  {/* <!-- Icon when menu is closed. --> */}
                  {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}

                  <svg
                    className={`${isOn ? "hidden" : "block"} h-6 w-6`}
                    // class={`${isOn ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  {/* <!-- Icon when menu is open. --> */}
                  {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                  <svg
                    className={`${isOn ? "block" : "hidden"} h-6 w-6`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-1 flex items-center lg:justify-between md:items-stretch justify-center menu-top">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      className="block md:hidden h-6 w-auto"
                      src={logo}
                      alt="РИШОН"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      className="hidden md:block h-7 w-auto"
                      src={logo}
                      alt="РИШОН"
                    />
                  </Link>
                </div>
                <div className="hidden flex lg:flex lg:ml-6 mr-40 max-w-6xl">
                  <div className="flex justify-center uppercase">
                    <Link
                      to="/projects/"
                      className="py-1 font-medium leading-5 hover:border-sand border-b-4 border-white focus:outline-none focus:border-sand transition duration-150 ease-in-out"
                    >
                      Проекты
                    </Link>
                    <Link
                      to="/contact/"
                      className="ml-20 py-1 font-medium leading-5 hover:border-sand border-b-4 border-white focus:outline-none focus:border-sand transition duration-150 ease-in-out"
                    >
                      Контакты
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  --> */}
          {/* <div className="hidden sm:hidden"> */}
          <div
            className={`lg:hidden w-full text-center navbar ${
              isOn ? "navbar-open" : ""
            }`}
          >
            <div className="px-6 pt-2 pb-3 sm:px-6 lg:px-16">
              <Link
                to="/projects/"
                className="block px-2 py-2 rounded-md text-base font-medium hover:text-black hover:bg-sand focus:outline-none focus:text-black focus:bg-sand transition duration-150 ease-in-out"
              >
                Проекты
              </Link>
              <Link
                to="/contact/"
                className="block mt-1 block px-2 py-2 rounded-md text-base font-medium hover:text-black hover:bg-sand focus:outline-none focus:text-black focus:bg-sand transition duration-150 ease-in-out"
              >
                Контакты
              </Link>
              {/* <a
              href="#"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
            >
              Projects
            </a>
            <a
              href="#"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
            >
              Calendar
            </a> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
